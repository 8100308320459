/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";

const AuthNavbar = () => {
  const { t } = useTranslation();

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-simbalotto-green opacity-9">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <a className="navbar-brand" href="#">
                <img
                  className="img-fluid"
                  src={require("assets/img/simbalotto-logo.png")}
                  alt="Playlottoonline Logo"
                  style={{ width: "200px" }}
                />
              </a>
            </div>
            <div className="col-6 justify-content-end d-flex">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarToggle"
                aria-controls="navbarToggle"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
          <div
            className="collapse navbar-collapse bg-simbalotto-green opacity-9"
            id="navbarToggle"
          >
            <div className="navbar-collapse-header d-md-none">
              <div className="row">
                <div className="col-6">
                  <a className="navbar-brand" href="#">
                    <img
                      src={require("assets/img/simbalotto-logo.png")}
                      alt="Logo"
                      width="100%"
                      height="100%"
                    />
                  </a>
                </div>
                <div className="col-6 collapse-close">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarToggle"
                    aria-controls="navbarToggle"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span />
                    <span />
                  </button>
                </div>
              </div>
            </div>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active text-dark"
                  aria-current="page"
                  href="https://playlottoonline.net/"
                >
                  {t("navbar.home")}
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link text-dark"
                  href="https://playlottoonline.net/faq/"
                >
                  {t("navbar.faq")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AuthNavbar;
